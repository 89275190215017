<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí cấu hình banner cho nhóm sản phẩm {{ category_title }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogBanner"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm banner</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Alt</th>
                    <th scope="col">Image</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(banner, i) in product_category_banners">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ banner.alt }}
                        </p>
                      </td>
                      <td>
                        <v-img
                          v-if="banner.url"
                          :src="banner.url"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editBanner(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteBanner(banner.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogBanner"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Thêm banner</span
              >
              <span class="headline" v-else>Sửa banner</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogBanner = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nội dung thẻ Alt:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="alt_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Ảnh:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0" id="image-banner">
                      <b-form-file
                        @change="onChange"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="url_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="url_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditBanner(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditBanner(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogBanner = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp cấu hình</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(banner, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ banner.alt }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortBanner"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  name: "ProductCategoryBanner",
  components: {
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      category_title: "",
      product_category_banners: [],
      product_category_banner: {},
      alt_input: "",
      url_data: null,
      url_file: null,
      type_form: null,
      product_category_banner_id: null,
      dialogBanner: false,
      data_draggable: [],
      dialogSort: false,
    };
  },
  computed: {
    category_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllCategoryProductBanner();
  },
  methods: {
    async getAllCategoryProductBanner() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/product-category/" + this.category_id + "/banner"
        );
        if (res.status === 200) {
          vm.category_title = res.data.category;
          vm.product_category_banners = res.data.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogBanner() {
      this.type_form = "add";
      this.alt_input = "";
      this.url_file = null;
      this.url_data = null;
      this.setDefaultLabelImgUSP();
      this.dialogBanner = true;
    },
    editBanner(index) {
      this.type_form = "update";
      let data = this.product_category_banners[index];
      this.product_category_banner_id = data.id;
      this.alt_input = data.alt;
      this.url_data = data.url;
      this.url_file = null;
      this.setDefaultLabelImgUSP();

      this.dialogBanner = true;
    },
    deleteBanner(banner_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/product-category/" +
              this.category_id +
              "/banner/" +
              banner_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllCategoryProductBanner();
                vm.$toasted.success("Xóa thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    validateData() {
      let flat = true;
      if (this.alt_input === "" || this.alt_input == null) {
        this.$toasted.error("Chưa nhập alt !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.url_data === null || this.url_data === "") {
        this.$toasted.error("Hãy nhập file ảnh !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.url_file != null) {
        if (
          !(
            this.getExtensionFile(this.url_file) === "jpeg" ||
            this.getExtensionFile(this.url_file) === "jpg" ||
            this.getExtensionFile(this.url_file) === "png"
          )
        ) {
          this.$toasted.error("Không đúng định dạng ảnh !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      return flat;
    },
    async btnAddOrEditBanner(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let formData = new FormData();
      formData.append("alt", this.alt_input);
      if (this.url_file != null) formData.append("url_file", this.url_file);

      this.is_call_api = true;
      if (type === 1) {
        formData.append("offset", this.product_category_banners.length + 1);
        await ApiService.post(
          "prep-app/product-category/" + this.category_id + "/banner",
          formData
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllCategoryProductBanner();
              vm.$toasted.success("Tạo thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = true;
              vm.dialogBanner = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = true;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        await ApiService.post(
          "prep-app/product-category/" +
            this.category_id +
            "/banner/" +
            this.product_category_banner_id,
          formData
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllCategoryProductBanner();
              vm.$toasted.success("Sửa thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = true;
              vm.dialogBanner = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = true;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.url_data = null;
        vm.url_file = null;
        vm.setDefaultLabelImgUSP();
        return;
      }
      vm.url_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.url_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    setDefaultLabelImgUSP() {
      let ele = document.getElementById("image-banner");
      if (ele) {
        ele.firstChild.children[1].firstChild.textContent =
          "Chọn tệp tin hoặc cancel để xóa";
      }
    },
    getExtensionFile(file) {
      let filename = file.name;
      return filename.split(".").pop();
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(
        JSON.stringify(this.product_category_banners)
      );
      this.dialogSort = true;
    },
    sortBanner() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              banner_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/product-category/" + this.category_id + "/banner/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllCategoryProductBanner();
                vm.$toasted.success("Sắp xếp thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
