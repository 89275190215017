import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=3804bd42&scoped=true"
import script from "./Banner.vue?vue&type=script&lang=js"
export * from "./Banner.vue?vue&type=script&lang=js"
import style0 from "./Banner.vue?vue&type=style&index=0&id=3804bd42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3804bd42",
  null
  
)

export default component.exports